import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import IndividualPageLayoutTemplate from  '../components/IndividualPageLayoutTemplate'
import '../css/global.scss'

 
export const IndividualIndustriesTemplate = ({
    pageName,
    bannerImg,
    bannerTitle,
    bannerDescription,
    introSectionTitle,
    introSectionDescription,
    // spotlightActivity,
    topicList
}) => {




//   const PageContent = contentComponent || Content

  return (
    <>

   <IndividualPageLayoutTemplate 
    pageName={pageName}
    bannerImg={bannerImg}
    bannerTitle={bannerTitle}
    bannerDescription={bannerDescription}
    introSectionTitle={introSectionTitle}
    introSectionDescription={introSectionDescription}
    // spotlightActivity={spotlightActivity}
    topicList={topicList}
    />
 
    </>
  )
}

IndividualIndustriesTemplate.propTypes = {
    pageName: PropTypes.string,
    bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    bannerTitle: PropTypes.string,
    bannerDescription: PropTypes.string,
    introSectionTitle: PropTypes.string,
    introSectionDescription: PropTypes.string,
    // spotlightActivity: PropTypes.array,
    topicList: PropTypes.array
  }

  const IndividualIndustriesPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
      return (
        <Layout
        title={frontmatter.bannerTitle}
        description={frontmatter.bannerDescription}
        >
              <IndividualIndustriesTemplate
                pageName={frontmatter.pageName}
                bannerImg={frontmatter.bannerImg}
                bannerTitle={frontmatter.bannerTitle}
                bannerDescription={frontmatter.bannerDescription}
                introSectionTitle={frontmatter.introSectionTitle}
                introSectionDescription={frontmatter.introSectionDescription}
                // spotlightActivity={frontmatter.spotlightActivity}
                topicList={frontmatter.topicList}
              />
        </Layout>
      )
}


IndividualIndustriesPage.propTypes = {
    data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    }),
}

export default IndividualIndustriesPage


export const pageQuery = graphql`
  query IndustryPostID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        pageName
        bannerImg {
            extension
            publicURL
          }
        bannerTitle
        bannerDescription
        introSectionTitle
        introSectionDescription
        topicList {
          title
          description
          thumbnail {
            extension
            publicURL
          }
        }
        
      }
    }
  }
`
